import React, { createContext, useState } from "react";
import logowhiteline from "../../assets/images/logo-white.svg";
import Favicon from "../../assets/images/favicon.ico";
import firstimg from "../../assets/images/login_bg.png";

const CompanyContext = createContext();

const CompanyProvider = ({ children }) => {
  const baseUrl = localStorage.getItem("BaseURL");
  const apiUrl = process.env.REACT_APP_ENDPOINT;
  const useDynamicDomain = process.env.REACT_APP_DYNAMIC_DOMAIN;
  const usePort =
    process.env.REACT_APP_DYNAMIC_API_PORT !== ""
      ? `:${process.env.REACT_APP_DYNAMIC_API_PORT}`
      : "";
  const useSuffix =
    process.env.REACT_APP_API_SUFFIX !== ""
      ? `/${process.env.REACT_APP_API_SUFFIX}`
      : "";

  const _BASE_URL =
    useDynamicDomain === "true"
      ? `${baseUrl}${usePort}${useSuffix}`
      : `${apiUrl}`;

  const [companyDetails, setCompanyDetails] = useState({
    title: localStorage.getItem("title") || "Ringopus",
    logo: localStorage.getItem("logo") || logowhiteline,
    favicon: localStorage.getItem("favicon") || Favicon,
    bgimage: localStorage.getItem("bgimage") || firstimg,
  });

  const currentTime = Date.now();

  const updateCompanyDetails = (newDetails) => {
    const name = newDetails.title;
    const logoUrl = `${_BASE_URL}${newDetails.logo}?${currentTime}`;
    const faviconUrl = `${_BASE_URL}${newDetails.favicon}?${currentTime}`;
    const bgImageUrl = `${_BASE_URL}${newDetails.bgimage}?${currentTime}`;
    
    setCompanyDetails(
      {
        title: name,
        logo: logoUrl,
        favicon: faviconUrl,
        bgimage: bgImageUrl,
      }
    );
    localStorage.setItem("title", name);
    localStorage.setItem("logo", logoUrl);
    localStorage.setItem("favicon", faviconUrl);
    localStorage.setItem("bgimage", bgImageUrl);
  };

  return (
    <CompanyContext.Provider value={{ companyDetails, updateCompanyDetails }}>
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyProvider, CompanyContext };
